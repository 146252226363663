body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ponizej reset wyglądu inputów Joomla: */
select, textarea, input[type="text"], input[type="password"], input[type="datetime"], 
input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], 
input[type="week"], input[type="number"], input[type="email"], input[type="url"], 
input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  background-color: transparent;
  border: none;
  height: 1.5em;
  padding: 1em;
  margin-bottom: unset;
  font-size: 1em;
  line-height: 1.5em;
  color: black;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 0%);
}
textarea:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 0%);
}

.MuiToolbar-root .MuiButtonBase-root a {
  color: white;
  text-decoration: none;
}
.MuiToolbar-root .MuiButtonBase-root a:hover {
  color: white;
  text-decoration: underline;
}
.otryckaDropZone {
  min-height: 160px !important;
}
/* .MuiDateRangeCalendar-root > div:first-child {
  visibility: hidden;
} */
